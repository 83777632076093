.scuba-diver-container {
  position: relative;
}

.scuba-diver {
  width: 100%;
  border-radius: 50%;
  position: relative; /* Add relative positioning */
  bottom: 20px; /* Adjust position upwards */
}

.bubble {
  position: absolute;
  border-radius: 50%;
  background-color: transparent; /* No fill color */
  border: 2px solid rgba(255, 255, 255, 0.8); /* White outline */
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.9); /* Glowing effect */
  animation: rise ease-in forwards;
  opacity: 0; /* Start as invisible */
}

@keyframes rise {
  0% {
    transform: translateY(0);
    opacity: 1; /* Start fully visible */
  }
  50% {
    opacity: 0.7; /* Fade slightly */
  }
  100% {
    transform: translateY(-600px); /* Move upwards further */
    opacity: 0; /* Fade out */
  }
}
