/* For the timeline elements */
.vertical-timeline-element-title {
    font-size: 1.5em;
    color: black !important; /* Title inside the box */
  }
  
  .vertical-timeline-element-description {
    color: black !important; /* Description inside the box */
  }
  
  /* Styling the date outside the box */
  .vertical-timeline-element-date {
    color: white !important; /* Date outside the box */
    font-size: 3.6em; /* Larger font for date */
  }
  
  /* Adjust for event title and description text outside the box */
  .vertical-timeline-element-date {
    font-size: 1.8em;
    font-weight: bold;
  }
  
  /* Customize the icon styles as needed */
  .vertical-timeline-element--work .vertical-timeline-element-icon {
    background-color: #06D6A0;
  }
  
  .vertical-timeline-element--school .vertical-timeline-element-icon {
    background-color: #f9c74f;
  }
  