/* Apply the body styles and background from your given code */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to left, rgb(24, 62, 212), rgb(2, 1, 4)); /* Gradient background */
}

/* Main Section */
.volunteering-section {
  padding: 50px 0;
}

.volunteer-img img {
  max-width: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.volunteer-img img:hover {
  transform: scale(1.05);
}

h1 {
  font-size: 2.5em;
  font-weight: bold;
}

.blue {
  color: #0d6efd;
}

p {
  color: #d8e0ed;
  font-size: 1.2em;
  line-height: 1.8;
}

.floating-img-container {
  position: relative;
}

.floating-img {
  animation: floating 4s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Contact Me Section */
.contact-section {
  padding: 50px 0;
}

.contact-info {
  text-align: center;
}

.contact-info h2 {
  font-size: 2em;
  font-weight: bold;
  color: #0d6efd;
}

.contact-info p {
  font-size: 1.2em;
  color: #333;
}

.contact-info a {
  color: #0d6efd;
  text-decoration: none;
}

/* Footer */
.footer {
  background-color: #333;
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer p {
  color: #d8e0ed;
  font-size: 1em;
}
/* Volunteering.css */
.volunteering-section {
    position: relative;
    z-index: 1; /* Ensure everything is in the right stacking order */
  }
  
  .particle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Make sure particles are behind the content */
  }
  
  .pdf-container {
    position: relative;
    z-index: 10; /* Ensure it's above the particles */
    margin: 20px auto;
    width: 100%;
  }
  
  iframe {
    width: 100%;
    height: 600px;
    border: none;
    z-index: 10;
  }
  